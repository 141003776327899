/** @format */

import { createStore } from 'vuex'
import { auth } from '../firebase'

export default createStore({
  state: {
    count: 0
  },
  mutations: {
    updateNotification (state, count) {
      state.count = count
    }
  },

  actions: {
    fetchPipLineHistory ({ commit }) {
      const json = {
        start: 0,
        end: 24,
        user: auth.currentUser.email.split('.')[0].toLowerCase()
      }
      const payload = JSON.stringify(json)
      auth.currentUser.getIdToken().then((token) => {
        const url = 'https://seelozairflow.azurewebsites.net/api/pipeline_history?auth=' + token + '&code=6PfLNoPJlpLwHT7EgNV3kUYLymqmH2kmvvWFFstB7RTrS1Pex8yz1g==&payload=' + payload
        fetch(url)
          .then((response) => response.json())
          .then(({ running_count = 0 }) => {
            commit('updateNotification', running_count)
          })
          .catch((err) => {
            console.log(err.message)
          })
      })
        .catch((err) => {
          console.log(err.message)
        })
    }
  }
})
