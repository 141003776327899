<template>
  <div>
    <div class="">
      <h1 class="row black-text center" >Admin Airflow Dashboard</h1>

      <div class="row container">
        <div class="col s12 m3">
          <div class="input-field left ">
            <select
              id="customer_selector"
              style="text-align: center;"
              class="browser-default grey lighten-1"
              v-model="selectedCustomer"
            >
              <option disabled selected value>Select Customer</option>
              <option v-for="customer in customers" v-bind:key="customer">
                {{ customer }}
              </option>
            </select>
          </div>
        </div>

        <div class="col s12 m3">
          <div class="input-field left ">
            <select
              id="platform_selector"
              style="text-align: center;"
              class="browser-default grey lighten-1"
              v-model="selectedPlatform"
            >
              <option disabled selected value>Cloud Platform</option>
              <option v-for="platform in platforms" v-bind:key="platform">
                {{ platform }}
              </option>
            </select>
          </div>
        </div>

        <div class="col s12 m3">
          <div class="input-field left ">
            <select
              id="mode_selector"
              class="browser-default grey lighten-1"
              v-model="selectedEnvironmentMode"
            >
              <option disabled selected value>Select Mode</option>
              <option v-for="mode in environmentModes" v-bind:key="mode">
                {{ mode }}
              </option>
            </select>
          </div>
        </div>
        <div class="col s12 m3">
          <div class="input-field left">
           <label>
                <input
                  type="checkbox"
                  class="filled-in"
                  v-model="discoveryMode"
                />
                <span class="black-text">Discovery</span>
              </label>
          </div>
        </div>
      </div>

      <div class="container row" id="incorta_settings">
        <h4 class="black-text">Pipeline Parameters</h4>
        <!-- <div class="col s6 m4">
          <div class="input-field" @mouseover.once="setActive()">
            <label for="hive_ip" v-bind:class="{ active: hiveIpLabel.active }">Enter Hive IP</label>
            <input type="text" id="hive_ip" class="adjust_dv" :placeholder="hiveIpLabel.placeholder" v-model.trim="hiveIp">
          </div>
        </div> -->
        <div v-if="selectedEnvironmentMode === 'personal'" class="col s6 m4">
          <div class="input-field" @mouseover.once="setActive()" >
            <label
              for="single_metric"
              v-bind:class="{ active: metricNameLabel.active }" black
              >Enter metric name and metric arg ; separated</label
            >
            <input
              type="text"
              id="single_metric"
              class="adjust_dv"
              :placeholder="metricNameLabel.placeholder"
              v-model.trim="metricName"
            />
          </div>
        </div>
        <div class="col s6 m4">
          <div class="input-field" @mouseover.once="setActive()">
            <label
              for="timestamp"
              v-bind:class="{ active: timestampLabel.active }"
              >Enter singlewarehouse timestamp</label
            >
            <input
              type="text"
              id="timestamp"
              class="adjust_dv"
              :placeholder="timestampLabel.placeholder"
              v-model.trim="timestamp"
            />
          </div>
        </div>
        <div
          style="background: transparent"
          v-if="selectedEnvironmentMode === 'personal'"
          class="col s6 m4"
        >
          <div
            class=" input-field"
            style="background: transparent; "
            @mouseover.once="setActive()"
          >
            <label v-bind:class="{ active: true }">Repo Branches: </label>
          </div>

          <n-cascader
            style="margin-top: 20px; margin-bottom: 25px"
            v-model:value="selectedBranches"
            multiple
            placeholder="Repository Branches"
            clearable
            max-tag-count="responsive"
            expand-trigger="click"
            :options="repoBranches"
            cascade="true"
            check-strategy="child"
            show-path="true"
            filterable="true"
          />
        </div>
      </div>
      <accordian>
        <template #inputFields>
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
              <label style="color:black"
                for="calendar_file"
                v-bind:class="{ active: calendarLabel.active }"
                >Enter calendar file name</label
              >
              <input
                type="text"
                id="calendar_file"
                class="adjust_dv"
                :placeholder="calendarLabel.placeholder"
                v-model.trim="calendarFileName"
              />
            </div>
          </div>
          <!-- <div class="col s6 m4">
          <div class="input-field" @mouseover.once="setActive()">
           <label style="color:black" for="max_no_of_jobs" v-bind:class="{ active: numJobsLabel.active }">Enter number of singlewarehouse jobs</label>
            <input type="text" id="max_no_of_jobs" class="adjust_dv" :placeholder="numJobsLabel.placeholder" v-model.trim="numJobs">
          </div>
        </div> -->
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="num_samples"
                v-bind:class="{ active: numSamplesLabel.active }"
                >Enter number of Samples</label
              >
              <input
                type="text"
                id="num_samples"
                class="adjust_dv"
                :placeholder="numSamplesLabel.placeholder"
                v-model.trim="numSamples"
              />
            </div>
          </div>
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="sw_algo"
                v-bind:class="{ active: singlewarehouseAlgoLabel.active }"
                >Enter Singlewarehouse algorithm</label
              >
              <input
                type="text"
                id="sw_algo"
                class="adjust_dv"
                :placeholder="singlewarehouseAlgoLabel.placeholder"
                v-model.trim="singlewarehouseAlgo"
              />
            </div>
          </div>
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="redis_ip"
                v-bind:class="{ active: redisIpLabel.active }"
                >Enter Redis IP</label
              >
              <input
                type="text"
                id="redis_ip"
                class="adjust_dv"
                :placeholder="redisIpLabel.placeholder"
                v-model.trim="redisIp"
              />
            </div>
          </div>
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="sw_prod_list"
                v-bind:class="{
                  active: singlewarehouseTrainingProdListLabel.active
                }"
                >Enter Singlewarehouse Training Prod List</label
              >
              <input
                type="text"
                id="sw_prod_list"
                class="adjust_dv"
                :placeholder="singlewarehouseTrainingProdListLabel.placeholder"
                v-model.trim="singlewarehouseTrainingProdList"
              />
            </div>
          </div>
          <!-- <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black" for="train_steps" v-bind:class="{ active: trainingStepsLabel.active }">Enter Training Steps</label>
              <input type="text" id="train_steps" class="adjust_dv" :placeholder="trainingStepsLabel.placeholder" v-model.trim="trainingSteps">
            </div>
          </div>
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black" for="rabbitmq" v-bind:class="{ active: rabbitmqHostLabel.active }">Enter RabbitMQ Host</label>
              <input type="text" id="rabbitmq" class="adjust_dv" :placeholder="rabbitmqHostLabel.placeholder" v-model.trim="rabbitmqHost">
            </div>
          </div> -->
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="validatio_d_rang"
                v-bind:class="{ active: validationDateRange.active }"
                >Validation_date_range</label
              >
              <input
                type="text"
                id="validatio_d_rang"
                class="adjust_dv"
                :placeholder="validationDateRange.placeholder"
                v-model.trim="validationDR"
              />
            </div>
          </div>
          <!-- <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black" for="apni_timestamp" v-bind:class="{ active: apniTimestamp.active }">Apni_timestamp</label>
              <input type="text" id="apni_timestamp" class="adjust_dv" :placeholder="apniTimestamp.placeholder" v-model.trim="apniTS">
            </div>
          </div> -->
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="etl_d_rang"
                v-bind:class="{ active: etlDateRange.active }"
                >ETL Date range</label
              >
              <input
                type="text"
                id="etl_d_rang"
                class="adjust_dv"
                :placeholder="etlDateRange.placeholder"
                v-model.trim="etlDR"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="training_d_range"
                v-bind:class="{ active: trainingDateRange.active }"
                >Training Date range</label
              >
              <input
                type="text"
                id="training_d_range"
                class="adjust_dv"
                :placeholder="trainingDateRange.placeholder"
                v-model.trim="trainingDR"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="sim_d_range"
                v-bind:class="{ active: simulationDateRange.active }"
                >Simulation Date range</label
              >
              <input
                type="text"
                id="sim_d_range"
                class="adjust_dv"
                :placeholder="simulationDateRange.placeholder"
                v-model.trim="simulationDR"
              />
            </div>
          </div>

          <div
            v-if="
              selectedEnvironmentMode === 'production' ||
                selectedEnvironmentMode === 'dev'
            "
            class="col s6 m4"
          >
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="data_pull_message"
                v-bind:class="{ active: dataInputJsonLabel.active }"
                >Data Pull Payload</label
              >
              <input
                type="text"
                id="data_pull_message"
                class="adjust_dv"
                :placeholder="dataInputJsonLabel.placeholder"
                v-model.trim="dataPullMessage"
              />
            </div>
          </div>

          <div v-if="selectedEnvironmentMode === 'personal'" class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="etl_tables"
                v-bind:class="{ active: etlTablesLabel.active }"
                >ETL Tables</label
              >
              <input
                type="text"
                id="etl_tables"
                class="adjust_dv"
                :placeholder="etlTablesLabel.placeholder"
                v-model.trim="etlTables"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="granularity"
                v-bind:class="{ active: granularityLabel.active }"
                >Granularity</label
              >
              <input
                type="text"
                id="granularity"
                class="adjust_dv"
                :placeholder="granularityLabel.placeholder"
                v-model.trim="granularity"
              />
            </div>
          </div>
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="test_config"
                v-bind:class="{ active: testconfigLabel.active }"
                >TestConfig</label
              >
              <input
                type="text"
                id="test_config"
                class="adjust_dv"
                :placeholder="testconfigLabel.placeholder"
                v-model.trim="testConfig"
              />
            </div>
          </div>
          <div class="col s6 m4">
            <div class="input-field">
             <label 
                for="currency_selector"
                v-bind:class="{ active: baseCurrency.active }"
                style="line-height: 11px;color:black"
              >
                Select Base Currency
              </label>

              <select
                id="currency_selector"
                style="text-align: center;"
                class="browser-default grey lighten-1"
                v-model="selectedBaseCurrency"
              >
                <option
                  v-for="currency in baseCurrencies"
                  v-bind:key="currency"
                >
                  {{ currency }}
                </option>
              </select>
            </div>
          </div>
          <div class="col s6 m5">
            <div class="input-field">
             <label
                for="demand_selector"
                v-bind:class="{ active: demandLabel.active }"
                style="line-height: 11px;color:black"
              >
                Select Demand table
              </label>
              <select
                id="demand_selector"
                style="text-align: center;"
                class="browser-default grey lighten-1"
                v-model="selectedDemandTable"
              >
                <option v-for="table in demandTables" v-bind:key="table">
                  {{ table }}
                </option>
              </select>
            </div>
          </div>
          <div class="col s6 m4">
            <div class="input-field">
             <label 
                for="traditional_model"
                v-bind:class="{ active: traditionalmodelLabel.active }"
                style="line-height: 11px;color:black"
              >
                Select traditional model
              </label>
              <select
                id="traditional_model"
                style="text-align: center;"
                class="browser-default grey lighten-1"
                v-model="selectedTraditionalModel"
              >
                <option v-for="model in traditionalModel" v-bind:key="model">
                  {{ model }}
                </option>
              </select>
            </div>
          </div>
          <div class="col s6 m4">
            <div class="input-field">
             <label 
                for="config_gen_mode"
                v-bind:class="{ active: configgenmodeLabel.active }"
                style="line-height: 11px;color:black"
              >
                Select config gen mode
              </label>
              <select
                id="config_gen_mode"
                style="text-align: center;"
                class="browser-default grey lighten-1"
                v-model="selectedConfigGenMode"
              >
                <option v-for="model in configgenMode" v-bind:key="model">
                  {{ model }}
                </option>
              </select>
            </div>
          </div>
        </template>
      </accordian>
      <accordian2>
        <template #inputFields>
           <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
              <label style="color:black"
                for="output_dir_rolling"
                v-bind:class="{ active: output_dirLabel.active }"
                >Enter Simulator Output Directory</label
              >
              <input
                type="text"
                id="output_dir_rolling"
                class="adjust_dv"
                :placeholder="output_dirLabel.placeholder"
                v-model.trim="output_dir"
              />
            </div>
            
          </div>
                    <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="simulation_date_range_rolling"
                v-bind:class="{ active: sim_d_range_rollingLabel.active }"
                >Enter Simulation Date Range</label
              >
              <input
                type="text"
                id="simulation_date_range_rolling"
                class="adjust_dv"
                :placeholder="sim_d_range_rollingLabel.placeholder"
                v-model.trim="sim_d_range_rolling"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="control_system"
                v-bind:class="{ active: control_systemLabel.active }"
                >Enter Control System Value</label
              >
              <input
                type="text"
                id="control_system"
                class="adjust_dv"
                :placeholder="control_systemLabel.placeholder"
                v-model.trim="control_system"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="apni_schedule_file"
                v-bind:class="{ active: apni_schedule_fileLabel.active }"
                >Enter APNI Schedule File Path (default: None)</label
              >
              <input
                type="text"
                id="apni_schedule_file"
                class="adjust_dv"
                :placeholder="apni_schedule_fileLabel.placeholder"
                v-model.trim="apni_schedule_file"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="apni_algo"
                v-bind:class="{ active: apni_algoLabel.active }"
                >Enter APNI Algo</label
              >
              <input
                type="text"
                id="apni_algo"
                class="adjust_dv"
                :placeholder="apni_algoLabel.placeholder"
                v-model.trim="apni_algo"
              />
            </div>
          </div>


          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="freq"
                v-bind:class="{ active: freqLabel.active }"
                >Enter Freq</label
              >
              <input
                type="text"
                id="freq"
                class="adjust_dv"
                :placeholder="freqLabel.placeholder"
                v-model.trim="freq"
              />
            </div>
          </div>
          
          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="num_cpus"
                v-bind:class="{ active: num_cpusLabel.active }"
                >Enter Num CPUs</label
              >
              <input
                type="text"
                id="num_cpus"
                class="adjust_dv"
                :placeholder="num_cpusLabel.placeholder"
                v-model.trim="num_cpus"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="train_periods"
                v-bind:class="{ active: train_periodsLabel.active }"
                >Enter Train Periods</label
              >
              <input
                type="text"
                id="train_periods"
                class="adjust_dv"
                :placeholder="train_periodsLabel.placeholder"
                v-model.trim="train_periods"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="val_periods"
                v-bind:class="{ active: val_periodsLabel.active }"
                >Enter Val Periods</label
              >
              <input
                type="text"
                id="val_periods"
                class="adjust_dv"
                :placeholder="val_periodsLabel.placeholder"
                v-model.trim="val_periods"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="demand_table_rolling"
                v-bind:class="{ active: demand_table_rollingLabel.active }"
                >Enter Demand Table</label
              >
              <input
                type="text"
                id="demand_table_rolling"
                class="adjust_dv"
                :placeholder="demand_table_rollingLabel.placeholder"
                v-model.trim="demand_table_rolling"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="mode_rolling"
                v-bind:class="{ active: mode_rollingLabel.active }"
                >Enter Mode</label
              >
              <input
                type="text"
                id="mode_rolling"
                class="adjust_dv"
                :placeholder="mode_rollingLabel.placeholder"
                v-model.trim="mode_rolling"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="base_schedule_path"
                v-bind:class="{ active: base_schedule_pathLabel.active }"
                >Enter Rolling Window Base Schedule Path</label
              >
              <input
                type="text"
                id="base_schedule_path"
                class="adjust_dv"
                :placeholder="base_schedule_pathLabel.placeholder"
                v-model.trim="base_schedule_path"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="base_path"
                v-bind:class="{ active: base_pathLabel.active }"
                >Enter GridSearch Base Path</label
              >
              <input
                type="text"
                id="base_path"
                class="adjust_dv"
                :placeholder="base_pathLabel.placeholder"
                v-model.trim="base_path"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="gridsearch_params"
                v-bind:class="{ active: gridsearch_paramsLabel.active }"
                >Enter Gridsearch Params</label
              >
              <input
                type="text"
                id="gridsearch_params"
                class="adjust_dv"
                :placeholder="gridsearch_paramsLabel.placeholder"
                v-model.trim="gridsearch_params"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="eval_config_path"
                v-bind:class="{ active: eval_config_pathLabel.active }"
                >Enter Eval Config Path</label
              >
              <input
                type="text"
                id="eval_config_path"
                class="adjust_dv"
                :placeholder="eval_config_pathLabel.placeholder"
                v-model.trim="eval_config_path"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="output_configparam_dir"
                v-bind:class="{ active: output_configparam_dirLabel.active }"
                >Enter Output Configparam Dir</label
              >
              <input
                type="text"
                id="output_configparam_dir"
                class="adjust_dv"
                :placeholder="output_configparam_dirLabel.placeholder"
                v-model.trim="output_configparam_dir"
              />
            </div>
          </div>

          <div class="col s6 m4">
            <div class="input-field" @mouseover.once="setActive()">
             <label style="color:black"
                for="max_episode_length"
                v-bind:class="{ active: max_episode_lengthLabel.active }"
                >Enter Max Episode Length</label
              >
              <input
                type="text"
                id="max_episode_length"
                class="adjust_dv"
                :placeholder="max_episode_lengthLabel.placeholder"
                v-model.trim="max_episode_length"
              />
            </div>
          </div>

          <div class="col s12 m3">
            <div class="input-field left">
             <label>
                  <input
                    type="checkbox"
                    class="filled-in"
                    v-model="run_Gridsearch"
                  />
                  <span class="black-text">Gridsearch</span>
                </label>
            </div>
          </div>

          <div class="col s12 m3">
            <div class="input-field left">
             <label>
                  <input
                    type="checkbox"
                    class="filled-in"
                    v-model="run_zscore_Tuning"
                  />
                  <span class="black-text">ZscoreTuning</span>
                </label>
            </div>
          </div>

        </template>
      </accordian2>
      <div class="row container">
        <div class="input-field left">
          <i class="material-icons prefix black-text">title</i>
          <input
            type="text"
            id="dag_name"
            title="based on mode"
            readonly
            class="gey lighten-3 adjust_div"
            :placeholder="dagPlaceholder"
            v-model="dagName"
          />
        </div>
        <div class="input-field right" v-if="submit_response.length < 1">
          <button
            class="btn green darken-2"
            :class="{ hide: submitDisabled }"
            :disabled="submitDisabled"
            id="submit_dag"
            v-on:click="submitDag()"
          >
            Submit
          </button>
        </div>
        <div class="input-field right" v-else>
          <button
            class="btn #E06635 darken-2 modal-trigger"
            id="response"
            data-target="modal-response"
          >
            Response
          </button>
        </div>
      </div>
      <div class="clearfix"></div>

      <div id="loader" class="center" :class="{ hide: !submitDisabled }">
        <!-- <div class="progress container">
              <div class="indeterminate"></div>
          </div> -->
        <h4 class="black-text">Sending Dag Request</h4>
        <div class="preloader-wrapper big active">
          <div class="spinner-layer spinner-green-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="checkContainer row">
        <div class="checkboxes" id="check1">
          <div
            v-for="step in columnSplit(
              steps,
              selectedEnvironmentMode === 'staging' ? 4 : 16,
              0
            )"
            v-bind:key="step"
          >
            <label>
              <input
                type="checkbox"
                class="filled-in"
                :value="step"
                v-model="selectedSteps"
              />
              <span class="black-text">{{ step }}</span>
            </label>
          </div>
        </div>
        <div class="checkboxes" id="check2">
          <div>
            <div
              v-for="step in columnSplit(
                steps,
                selectedEnvironmentMode === 'staging' ? 4 : 16,
                1
              )"
              v-bind:key="step"
            >
              <label>
                <input
                  type="checkbox"
                  class="filled-in"
                  :value="step"
                  v-model="selectedSteps"
                />
                <span class="black-text">{{ step }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="container row">
        <a :href="airflowBtn.href" target="_blank">
          <button
            :disabled="airflowBtn.disabled"
            id="airflow_btn"
            class="btn left #E06635 darken-2"
          >
            Check dag status on airflow
          </button>
        </a>
        <button
          v-on:click="clear()"
          id="refresh_btn"
          class="btn right darken-2" style="background:#E06635"
        >
          Create New Dag
        </button>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="container" id="graphstuff">
        <!-- <h3 class="black-text">Your Jobs Flow</h3> -->
        <div id="graph_container" class="white center"></div>
      </div>
    </div>
    <br />
  </div>
</template>

<script lang="js">
import { ipCollection, auth, stepsDoc, stagingStepDoc, azureStepsDoc, azureDiscoveryStepsDoc, azureStagingStepDoc, sapCustomers } from '../firebase'
// import VueTagsInput from '@johmun/vue-tags-input'
// import TagsInput from './TagsInput.vue'
import Accordian from './accordian.vue'
import accordian2 from './accordian_rolling.vue'
export default {
  name: 'CreatePipeline',
  components: {  Accordian , accordian2 },  
  data () {
    return {
      steps: {},
      gcpSteps: {},
      gcpStagingSteps: {},
      azureSteps: {},
      azureTmpSteps: {},
      azureStagingSteps: {},
      platforms: ['Azure'],
      customers: [],
      selectedBranches: [],
      repoBranches: [],
      granularity: "customer_id",
      testConfig: "metrics_layer_tests.json",
      baseCurrencies: ['MYR', 'USD', 'THB', 'SAR'],
      selectedCustomer: '',
      selectedPlatform: 'Azure',
      etlDR: '',
      trainingDR: '',
      simulationDR: '',
      etlTables: '',
      selectedBaseCurrency: 'USD',
      demandTables: ['daily_demand_from_orders', 'daily_demand_from_deliveries'],
      selectedDemandTable: 'daily_demand_from_orders',
      traditionalModel: ['EOQ', 'TPOP', 'ROP'],
      configgenMode: ['raw', 'distribution', 'production'],
      selectedConfigGenMode: 'raw',
      selectedTraditionalModel: 'ROP',
      environmentModes: ['personal', 'production', 'dev', 'staging'],
      selectedEnvironmentMode: 'personal',
      output_dir: "rolling_window/simulator_results",
      freq: '2',
      num_cpus: '4',
      train_periods: '6',
      val_periods: '1',
      demand_table_rolling: "daily_demand_from_orders",
      mode_rolling: "raw",
      base_schedule_path: "rolling_window/base_schedule_path/",
      base_path: "rolling_window/gridsearch/",
      gridsearch_params: "rolling_window/gridsearch_params.json",
      eval_config_path: "rolling_window/evaluation_configs.json",
      output_configparam_dir: "rolling_window/config_param/",
      max_episode_length: '4',
      sim_d_range_rolling: '2022-01-01:2022-06-30',
      control_system: "jit",
      apni_schedule_file: '',
      apni_algo: "DQN",
      dataPullMessage: '',
      selectedSteps: [],
      // hiveIpLabel: {
      //   active: false,
      //   placeholder: ''
      // },
      etlTablesLabel: {
        active: false,
        placeholder: ''
      },
      etlDateRange: {
        active: false,
        placeholder: ''
      },
      trainingDateRange: {
        active: false,
        placeholder: ''
      },
      simulationDateRange: {
        active: false,
        placeholder: ''
      },
      redisIpLabel: {
        active: false,
        placeholder: ''
      },
      timestampLabel: {
        active: false,
        placeholder: ''
      },
      trainingStepsLabel: {
        active: false,
        placeholder: ''
      },
      numJobsLabel: {
        active: false,
        placeholder: ''
      },
      metricNameLabel: {
        active: false,
        placeholder: ''
      },
      dataInputJsonLabel: {
        active: false,
        placeholder: ''
      },
      // branchNameLabel: {
      //   active: false
      // },
      calendarLabel: {
        active: false,
        placeholder: ''
      },
      validationDateRange: {
        active: false,
        placeholder: ''
      },
      apniTimestamp: {
        active: false,
        placeholder: ''
      },
      baseCurrency: {
        active: false
      },
      demandLabel: {
        active: false
      },
      traditionalmodelLabel: {
        active: false
      },
      configgenmodeLabel: {
        active: false
      },
      singlewarehouseAlgoLabel: {
        active: false,
        placeholder: ''
      },
      singlewarehouseTrainingProdListLabel: {
        active: false,
        placeholder: ''
      },
      numSamplesLabel: {
        active: false,
        placeholder: ''
      },
      rabbitmqHostLabel: {
        active: false,
        placeholder: ''
      },
      granularityLabel: {
        active: false,
        placeholder: ''
      },
      testconfigLabel: {
        active: false,
        placeholder: ''
      },
      output_dirLabel: {
        active: true,
        placeholder: ''
      },
      sim_d_range_rollingLabel: {
        active: true,
        placeholder: ''
      },
      control_systemLabel: {
        active: true,
        placeholder: ''
      },
      apni_schedule_fileLabel: {
        active: false,
        placeholder: ''
      },
      apni_algoLabel: {
        active: true,
        placeholder: ''
      },
      freqLabel: {
        active: true,
        placeholder: ''
      },
      num_cpusLabel: {
        active: true,
        placeholder: ''
      },
      train_periodsLabel: {
        active: true,
        placeholder: ''
      },
      val_periodsLabel: {
        active: true,
        placeholder: ''
      },
      demand_table_rollingLabel: {
        active: true,
        placeholder: ''
      },
      mode_rollingLabel: {
        active: true,
        placeholder: ''
      },
      base_schedule_pathLabel: {
        active: true,
        placeholder: ''
      },
      base_pathLabel: {
        active: true,
        placeholder: ''
      },
      gridsearch_paramsLabel: {
        active: true,
        placeholder: ''
      },
      eval_config_pathLabel: {
        active: true,
        placeholder: ''
      },
      output_configparam_dirLabel: {
        active: true,
        placeholder: ''
      },
      max_episode_lengthLabel: {
        active: true,
        placeholder: ''
      },
      stepsKeys: [],
      // hiveIp: '',
      redisIp: '',
      singlewarehouseAlgo: '',
      singlewarehouseTrainingProdList: '',
      timestamp: '',
      numSamples: '',
      // branchName: 'dev',
      trainingSteps: '',
      rabbitmqHost: '',
      ipDictDev: {},
      ipDictProd: {},
      ipDictPersonal: {},
      dagName: '',
      dagPlaceholder: '##Dag Name',
      dagTag: new Date().getTime(),
      user: '',
      calendarFileName: '',
      numJobs: '',
      metricName: '',
      apniTS: '',
      validationDR: '',
      dagFlow: {},
      airflowBtn: {
        disabled: true,
        href: '#'
      },
      submitDisabled: false,
      submit_response: '',
      stagingSteps: {},
      pipelineSteps: {},
      azureDiscoverySteps: {},
      discoveryMode: false,
      run_gridSearch: true,
      run_zscore_Tuning: false
    }
  },
  methods: {
    toJSONLocal (date) {
      const local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    },
    populateRepoBranches() {
      auth.currentUser.getIdToken().then(token => {
        fetch("https://seelozairflow.azurewebsites.net/api/get_branches?code=a2caHwhBno6iZCbyf26m9m0y/9/jGGrS5jANwtK9GIfU8gVaZTRsbw==&auth="+ token)
          .then(response => response.json())
          .then(json => {
            Object.keys(json).forEach(repo => {
              this.repoBranches.push({
                value: repo,
                label: repo,
                children: json[repo].map(branch => ({value: `${repo}:${branch}`, label: branch}))
              })
            })
          }).catch(err => {
            alert("Error in populating branches " + err)
          })
      })
    },
    fetchPipLineHistory (pipeline_id, platform) {
      const payload = JSON.stringify({ recreation: true, pipeline_id })
      auth.currentUser.getIdToken().then((token) => {
        const headers = platform === 'GCP' ? {
          headers: new Headers({
            Authorization: token
          })
        } : undefined
        let url
        if (platform === 'GCP') {
          url = 'https://us-central1-development-233900.cloudfunctions.net/pipeline_history?payload=' + payload
        } else {
          url = 'https://seelozairflow.azurewebsites.net/api/pipeline_history?auth=' + token + '&code=6PfLNoPJlpLwHT7EgNV3kUYLymqmH2kmvvWFFstB7RTrS1Pex8yz1g==&payload=' + payload
        }
        fetch(url, headers)
          .then((response) => response.json())
          .then(({ message, dag, data, mode, customer }) => {
            if (message) {
              throw message
            }
            const {
              apni_timestamp,
              calendar_file,
              // hive_ip,
              max_jobs,
              metric,
              redis_ip,
              singlewarehouse_timestamp,
              validation_dateRange,
              base_currency,
              // branch,
              date_range,
              num_samples,
              rabbitmq_host,
              singlewarehouse_algo,
              singlewarehouse_training_prod_list,
              train_steps,
              repo_tags,
              demand_table,
              traditional_model,
              config_gen_mode,
              training_date_range,
              simulation_date_range,
              etl_tbl,
              granularity,
              test_config,
              output_dir,
              sim_d_range_rolling,
              control_system,
              apni_schedule_file,
              apni_algo,
              run_gridsearch,
              freq,
              num_cpus,
              run_zscore_tuning,
              train_periods,
              val_periods,
              demand_table_rolling,
              mode_rolling,
              base_schedule_path,
              base_path,
              gridsearch_params,
              eval_config_path,
              output_configparam_dir,
              max_episode_length,
              data_pull_message,
              discovery_mode
            } = data
            dag.forEach(el => this.selectedSteps.push(el))
            this.apniTS = apni_timestamp
            this.calendarFileName = calendar_file
            // this.hiveIp = hive_ip
            this.redisIp = redis_ip
            this.timestamp = singlewarehouse_timestamp
            this.metricName = metric
            this.validationDR = validation_dateRange
            this.numJobs = max_jobs
            this.selectedEnvironmentMode = mode
            this.selectedCustomer = customer
            this.selectedBaseCurrency = base_currency
            // this.branchName = branch
            this.etlDR = date_range
            this.trainingDR = training_date_range || ''
            this.simulationDR = simulation_date_range || ''
            this.numSamples = num_samples
            this.rabbitmqHost = rabbitmq_host
            this.singlewarehouseAlgo = singlewarehouse_algo
            this.singlewarehouseTrainingProdList = singlewarehouse_training_prod_list
            this.trainingSteps = train_steps
            this.selectedPlatform = platform
            this.repoTags = repo_tags || []
            this.selectedDemandTable = demand_table
            this.selectedModel = traditional_model
            this.selectedConfigGenMode= config_gen_mode
            this.etlTables = etl_tbl
            this.granularity = granularity
            this.testConfig = test_config
            this.output_dir = output_dir
            this.sim_d_range_rolling = sim_d_range_rolling
            this.control_system = control_system
            this.apni_schedule_file = apni_schedule_file
            this.apni_algo = apni_algo
            this.run_Gridsearch = run_gridsearch || true
            this.freq = freq,
            this.num_cpus = num_cpus,
            this.run_zscore_Tuning = run_zscore_tuning || false
            this.train_periods = train_periods,
            this.val_periods = val_periods,
            this.demand_table_rolling = demand_table_rolling,
            this.mode_rolling = mode_rolling,
            this.base_schedule_path = base_schedule_path,
            this.base_path = base_path,
            this.gridsearch_params = gridsearch_params,
            this.eval_config_path = eval_config_path,
            this.output_configparam_dir = output_configparam_dir,
            this.max_episode_length = max_episode_length,
            this.discoveryMode = discovery_mode || false
            if (this.selectedEnvironmentMode === 'production' || this.selectedEnvironmentMode === 'dev' || this.selectedEnvironmentMode === 'staging') {
              this.dagName = this.selectedCustomer.toLowerCase() + new Date().getTime()
            } else if (this.selectedEnvironmentMode === 'personal') {
              this.dagName = this.user + new Date().getTime()
            }
            if(this.selectedEnvironmentMode === 'production' || this.selectedEnvironmentMode === 'dev'){
              this.dataPullMessage = data_pull_message
            }
          })
          .catch((err) => {
            alert('Error: ' + err)
            console.log(err)
          })
      })
    },
    clear () {
      this.selectedSteps = []
      this.selectedCustomer = ''
      this.selectedBaseCurrency = 'USD'
      this.selectedPlatform = ''
      this.selectedEnvironmentMode = 'personal'
      // this.hiveIp = ''
      this.redisIp = ''
      this.timestamp = ''
      this.dagName = ''
      this.validationDR = ''
      this.apniTS = ''
      this.dagPlaceholder = '##Dag Name'
      this.calendarFileName = ''
      this.numJobs = ''
      this.metricName = ''
      this.airflowBtn.href = '#'
      this.submitDisabled = false
      this.submit_response = ''
      // this.branchName = 'dev'
      this.etlDR = ''
      this.trainingDR = ''
      this.simulationDR = ''
      this.numSamples = ''
      this.rabbitmqHost = ''
      this.singlewarehouseAlgo = ''
      this.singlewarehouseTrainingProdList = ''
      this.trainingSteps = ''
      this.etlTables = ''
      this.testConfig = "metrics_layer_tests.json"
      this.output_dir = "rolling_window/simulator_results/"
      this.sim_d_range_rolling = ''
      this.control_system = "jit"
      this.apni_schedule_file = ''
      this.apni_algo = "DQN"
      this.run_Gridsearch = true
      this.freq = '2'
      this.num_cpus = '4'
      this.run_zscore_Tuning = false
      this.train_periods = '6'
      this.val_periods = '1'
      this.demand_table_rolling = "daily_demand_from_orders"
      this.mode_rolling = ''
      this.base_schedule_path = "rolling_window/base_schedule_path/"
      this.base_path = "rolling_window/gridsearch/"
      this.gridsearch_params = "rolling_window/gridsearch_params.json"
      this.eval_config_path = "rolling_window/evaluation_configs.json"
      this.output_configparam_dir = "rolling_window/config_param/"
      this.max_episode_length = '4'
      this.granularity = "customer_id,site_id"
      this.repoTags = []
      this.dataPullMessage = ''
      this.discoveryMode = false
    },
    validateForm () {
      const requiredParams = {
        // hiveIP: ['submit_spark_etl', 'submit_spark_m_d', 'submit_spark_model', 'dashboard_apni_prep'],
        redisIP: ['submit_spark_etl']
        // timestamp: ['singlewarehouse_training', 'dashboard_apni_prep', 'big_query_load_tables_apni'],
        // calendarFile: ['singlewarehouse_training', 'singlewarehouse_generator'],
        // maxNoJobs: ['singlewarehouse_training']
        // validationDR: ['layer_dbprep_validation'], // DR => Date Range
        // apniTS: ['layer_dbprep_validation'] // TS => Time Stamp
      }
      const necessaryParams = []
      for (let i = 0; i < this.selectedSteps.length; i++) {
        const job = this.selectedSteps[i]
        // if (requiredParams.hiveIP.includes(job) && this.hiveIp.length === 0) {
        //   if (necessaryParams.indexOf(' Hive IP') === -1) necessaryParams.push(' Hive IP')
        // }
        if (requiredParams.redisIP.includes(job) && this.redisIp.length === 0) {
          if (necessaryParams.indexOf(' Redis IP') === -1) necessaryParams.push(' Redis IP')
        }
        // if (requiredParams.timestamp.includes(job) && this.timestamp.length === 0) {
        //   if (necessaryParams.indexOf(' Singlewarehouse Timestamp') === -1) necessaryParams.push(' Singlewarehouse Timestamp')
        // }
        // if (requiredParams.calendarFile.includes(job) && this.calendarFileName.length === 0) {
        //   if (necessaryParams.indexOf(' Calendar File Name') === -1) necessaryParams.push(' Calendar File Name')
        // }
        // if (requiredParams.maxNoJobs.includes(job) && this.numJobs.length === 0) {
        //   if (necessaryParams.indexOf(' Number of Jobs') === -1) necessaryParams.push(' Number of Jobs')
        // }
        // if (requiredParams.validationDR.includes(job) && this.validationDR.length === 0) {
        //   if (necessaryParams.indexOf(' Validation Date Range') === -1) necessaryParams.push(' Validation Date Range')
        // }
        // if (requiredParams.apniTS.includes(job) && this.apniTS.length === 0) {
        //   if (necessaryParams.indexOf(' Apni Timestamp') === -1) necessaryParams.push(' Apni Timestamp')
        // }
      }
      return necessaryParams
    },
    submitDag () {
      const unfilledParams = this.validateForm()
      if (!this.dagName || !this.selectedCustomer || !this.selectedEnvironmentMode) {
        alert('Dag creation requirements not fulfilled.')
        return
      } else if (unfilledParams.length !== 0 || this.selectedSteps.length === 0) {
        if (this.selectedSteps.length === 0) {
          alert('No job(s) selected!')
          return
        } else {
          alert('Required parameter(s) missing:' + unfilledParams)
          return
        }
      }
      let isError = false
      let repos = []
      this.selectedBranches.forEach(branch => {
        if (isError) { return }
        if (!branch.includes(':')) {
          isError = true
          return alert('Invalid format for Repository Branches.')
        }
        const [repo] = branch.split(':')
        if (repos.includes(repo)) {
          isError = true
          return alert(`Only single configuration is allowed per repo: ${repo} `)
        }
        repos.push(repo)
      })
      if (isError)
        return
      const data = {
        // hive_ip: this.hiveIp,
        redis_ip: this.redisIp,
        singlewarehouse_timestamp: this.timestamp,
        calendar_file: this.calendarFileName,
        max_jobs: this.numJobs,
        metric: this.metricName,
        user: this.user,
        slack_username:this.slack_username,
        apni_timestamp: this.apniTS,
        validation_dateRange: this.validationDR,
        base_currency: this.selectedBaseCurrency,
        // branch: this.branchName,
        date_range: this.etlDR,
        training_date_range: this.trainingDR,
        simulation_date_range: this.simulationDR,
        num_samples: this.numSamples,
        rabbitmq_host: this.rabbitmqHost,
        singlewarehouse_algo: this.singlewarehouseAlgo,
        singlewarehouse_training_prod_list: this.singlewarehouseTrainingProdList,
        train_steps: this.trainingSteps,
        repo_tags: this.selectedBranches,
        demand_table: this.selectedDemandTable,
        traditional_model: this.selectedTraditionalModel,
        config_gen_mode: this.selectedConfigGenMode,
        etl_tbl: this.etlTables,
        test_config: this.testConfig,
        output_dir: this.output_dir,
        sim_d_range_rolling: this.sim_d_range_rolling,
        control_system: this.control_system,
        apni_schedule_file: this.apni_schedule_file,
        apni_algo: this.apni_algo,
        run_gridsearch: this.run_Gridsearch,
        freq: this.freq,
        num_cpus: this.num_cpus,
        run_zscore_tuning: this.run_zscore_Tuning,
        train_periods: this.train_periods,
        val_periods: this.val_periods,
        demand_table_rolling: this.demand_table_rolling,
        mode_rolling: this.mode_rolling,
        base_schedule_path: this.base_schedule_path,
        base_path: this.base_path,
        gridsearch_params: this.gridsearch_params,
        eval_config_path: this.eval_config_path,
        output_configparam_dir: this.output_configparam_dir,
        max_episode_length: this.max_episode_length,
        granularity: this.granularity,
        discovery_mode: this.discoveryMode
      }
      // if (this.selectedEnvironmentMode === 'development') {
      //   // getting first name of user
      //   data.user = this.user
      // }
      let dag = this.sortSteps(this.steps, this.selectedSteps)
       if (this.selectedEnvironmentMode === 'production' || this.selectedEnvironmentMode === 'dev') {
         if (dag.includes("data_pull_trigger")) {
          if(this.isJsonString(this.dataPullMessage))
            data.data_pull_payload = this.dataPullMessage
          else
            return alert("Invalid JSON for Data Pull message field.")
        }
      }
      this.submitDisabled = true
      // sorting steps based on order

      const json = {
        pipeline: this.dagName,
        mode: this.selectedEnvironmentMode,
        customer: this.selectedCustomer,
        dag: dag,
        data: data,
        debug: false
      }
      const json_string = JSON.stringify(json)
      auth.currentUser.getIdToken().then(token => {
        let url
        if (this.selectedPlatform === 'GCP') { url = 'https://us-central1-development-233900.cloudfunctions.net/generate_dag_flow?payload=' + json_string } else if (this.selectedPlatform === 'Azure') { url = 'https://seelozairflow.azurewebsites.net/api/generate_airflow_dag?auth=' + token + '&code=9HZ/ap2QsSoxaYy3XAtOPRK/jtsgP8mz39FjwrCWfRIccYNWwK2eow==&payload=' + json_string } else { return alert('No Cloud Platform selected.') }
        const headers = this.selectedPlatform === 'GCP' ? {
          headers: new Headers({
            Authorization: token
          })
        } : undefined
        fetch(url, headers)
          .then(res => {
            return res.text()
          })
          .then(data => {
            this.airflowBtn.disabled = false
            this.submitDisabled = false
            this.submit_response = data
            document.querySelector('#modal-response p').innerHTML = this.submit_response
            if (this.selectedPlatform === 'GCP') { this.airflowBtn.href = 'https://o4b18a577626fe3bap-tp.appspot.com/admin/airflow/graph?dag_id=' + this.dagName } else if (this.selectedPlatform === 'Azure') { this.airflowBtn.href = 'https://airflow.seeloz.com/graph?dag_id=' + this.dagName }
          })
          .catch(err => {
            alert('Error: '+ err)
            this.submitDisabled = false
            this.submit_response = err
            document.querySelector('#modal-response p').innerHTML = this.submit_response
          })
      })
    },
    setActive () {
      if (this.redisIpLabel.active !== true) {
        // this.hiveIpLabel.active = true
        // this.hiveIpLabel.placeholder = 'Hive IP'
        this.redisIpLabel.active = true
        this.redisIpLabel.placeholder = 'Redis IP'
        this.timestampLabel.active = true
        this.timestampLabel.placeholder = 'timestamp'
        this.calendarLabel.active = true
        this.calendarLabel.placeholder = 'calendar_dominos_pipeline_full_2019.json'
        this.numJobsLabel.active = true
        this.numJobsLabel.placeholder = '-1'
        this.metricNameLabel.active = true
        this.metricNameLabel.placeholder = 'eg Purchase;m+d/m'
        this.apniTimestamp.active = true
        this.apniTimestamp.placeholder = 'Apni Timestamp'
        this.validationDateRange.active = true
        this.validationDateRange.placeholder = 'Validation Date Range'
        this.baseCurrency.active = true
        // this.branchNameLabel.active = true
        this.etlDateRange.active = true
        this.trainingDateRange.active = true
        this.simulationDateRange.active = true
        this.numSamplesLabel.active = true
        this.numSamplesLabel.placeholder = '2'
        this.singlewarehouseAlgoLabel.active = true
        this.singlewarehouseAlgoLabel.placeholder = 'DQN'
        this.singlewarehouseTrainingProdListLabel.active = true
        this.singlewarehouseTrainingProdListLabel.placeholder = '2401_1401469657'
        this.trainingStepsLabel.active = true
        this.trainingStepsLabel.placeholder = '200000'
        this.rabbitmqHostLabel.active = true
        this.demandLabel.active = true
        this.traditionalmodelLabel.active = true
        this.configgenmodeLabel.active = true
        this.etlTablesLabel.active = true
        this.testconfigLabel.active = true
        this.granularityLabel.active = true
        this.dataInputJsonLabel.active = true
      }
    },
    replaceDag () {
      // PROCESS DAG TAG HERE
      if (this.selectedCustomer !== '' && this.selectedEnvironmentMode !== '' && this.selectedPlatform !== '') {
        if (this.selectedEnvironmentMode === 'production' || this.selectedEnvironmentMode === 'dev' || this.selectedEnvironmentMode === 'staging') {
          this.dagName = this.selectedCustomer.toLowerCase() + new Date().getTime()
        } else if (this.selectedEnvironmentMode === 'personal') {
          this.dagName = this.user + new Date().getTime()
        }
      } else if (this.selectedCustomer !== '' && this.selectedEnvironmentMode === '') {
        this.dagPlaceholder = 'No mode selected'
      } else if (this.selectedEnvironmentMode !== '' && this.selectedCustomer === '') {
        this.dagPlaceholder = 'No customer selected'
      } else if (this.selectedCustomer !== '' && this.selectedEnvironmentMode !== '' && this.selectedPlatform === '') {
        this.dagPlaceholder = 'No Cloud platform selected'
      }
      if (this.selectedCustomer === 'Ingress' || this.selectedCustomer === 'Ingress2') {
        this.selectedBaseCurrency = 'THB'
      }
    },
    columnSplit (steps, numRows, currColumn) { // CURR COLUMN IS 0-BASED
      const temp = Object.keys(this.steps)
      const columnSteps = []
      for (let i = currColumn * numRows; i < currColumn * numRows + numRows && i < temp.length; i++) {
        for (const step in steps) {
          if (steps[step].order === i) {
            columnSteps.push(step)
          }
        }
      }
      return columnSteps
    },
    sortSteps (steps, selectedSteps) {
      const dag = selectedSteps
      for (let i = 1; i < dag.length; i++) {
        for (let j = i - 1; j >= 0; j--) {
          if (steps[dag[j]].order > steps[dag[j + 1]].order) {
            const temp = dag[j]
            dag[j] = dag[j + 1]
            dag[j + 1] = temp
          } else {
            break
          }
        }
      }
      return dag
    },
    isJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }
  },
  watch: {
    selectedCustomer: function (val) {
      if (val.length > 0) {
        let ips
        if (this.selectedEnvironmentMode === 'production') {
          ips = this.ipDictProd[this.selectedCustomer]
        } else if (this.selectedEnvironmentMode === 'dev') {
          ips = this.ipDictDev[this.selectedCustomer]
        } else {
          ips = this.ipDictPersonal[this.selectedCustomer]
        }
        // this.hiveIp = ips.hive
        this.redisIp = ips.redis
        this.etlDR = ips.etlDR
        this.trainingDR = ips.trainingDR
        this.simulationDR = ips.simulationDR
        if (this.selectedPlatform === 'GCP') {
          this.rabbitmqHost = ips.rabbitMqGCP
        } else if (this.selectedPlatform === 'Azure') {
          this.rabbitmqHost = ips.rabbitMqAzure
        }
        this.timestamp = ''
        sapCustomers.get().then(doc => {
          const sap = doc.data().names
          if (sap.includes(val) && this.selectedPlatform === 'Azure') {
            this.steps = this.azureTmpSteps
          } else if (this.selectedPlatform === 'Azure') {
            if (this.discoveryMode) {this.steps = this.azureDiscoverySteps } else {this.steps = this.azureSteps }
          } else {
            this.steps = this.gcpSteps
          }
        })
        if (val) { this.replaceDag() }
        /* check selected jobs
        var new_dag_keys = Object.keys(dag)
        for(var i=0;i<new_dag_keys.length;i++){
            var key=new_dag_keys[i]
            document.querySelector('form input[value='+key +']').checked=1
        } */
      }
    },
    selectedEnvironmentMode: function () {
      if (this.selectedPlatform === 'GCP') {
        this.pipelineSteps = this.gcpSteps
        this.stagingSteps = this.gcpStagingSteps
      } else if (this.selectedPlatform === 'Azure') {
        this.pipelineSteps = this.azureSteps
        this.stagingSteps = this.azureStagingSteps
      }
      if (this.selectedEnvironmentMode === 'staging') {
        this.steps = this.stagingSteps
      } else {
        this.steps = this.pipelineSteps
      }
      if (Object.keys(this.steps).length > 0) { this.stepsKeys = Object.keys(this.steps).sort() }
      if (this.selectedCustomer.length > 0) {
        let ips
        if (this.selectedEnvironmentMode === 'production') {
          ips = this.ipDictProd[this.selectedCustomer]
        } else if (this.selectedEnvironmentMode === 'dev') {
          ips = this.ipDictDev[this.selectedCustomer]
        } else {
          ips = this.ipDictPersonal[this.selectedCustomer]
        }
        // this.hiveIp = ips.hive
        this.redisIp = ips.redis
        this.etlDR = ips.etlDR
        this.trainingDR = ips.trainingDR
        this.simulationDR = ips.simulationDR
        if (this.selectedPlatform === 'GCP') {
          this.rabbitmqHost = ips.rabbitMqGCP
        } else if (this.selectedPlatform === 'Azure') {
          this.rabbitmqHost = ips.rabbitMqAzure
        }
      }
      sapCustomers.get().then(doc => {
        const sap = doc.data().names
        if (sap.includes(this.selectedCustomer) && this.selectedPlatform === 'Azure') {
          this.steps = this.azureTmpSteps
        } else if (this.selectedPlatform === 'Azure') {
          if (this.discoveryMode) {this.steps = this.azureDiscoverySteps } else {this.steps = this.azureSteps }
        } else {
          this.steps = this.gcpSteps
        }
      })
      this.replaceDag()
    },
    selectedPlatform: function () {
      // this.steps = this.stagingSteps
      if (this.selectedPlatform === 'GCP') {
        this.pipelineSteps = this.gcpSteps
        this.stagingSteps = this.gcpStagingSteps
      } else if (this.selectedPlatform === 'Azure') {
        this.pipelineSteps = this.azureSteps
        this.stagingSteps = this.azureStagingSteps
      }
      if (this.selectedEnvironmentMode === 'staging') {
        this.steps = this.stagingSteps
      } else {
        this.steps = this.pipelineSteps
      }
      if (Object.keys(this.steps).length > 0) { this.stepsKeys = Object.keys(this.steps).sort() }
      if (this.selectedCustomer.length > 0) {
        let ips
        if (this.selectedEnvironmentMode === 'production') {
          ips = this.ipDictProd[this.selectedCustomer]
        } else if (this.selectedEnvironmentMode === 'dev') {
          ips = this.ipDictDev[this.selectedCustomer]
        } else {
          ips = this.ipDictPersonal[this.selectedCustomer]
        }
        // this.hiveIp = ips.hive
        this.redisIp = ips.redis
        this.etlDR = ips.etlDR
        this.trainingDR = ips.trainingDR
        this.simulationDR = ips.simulationDR
        if (this.selectedPlatform === 'GCP') {
          this.rabbitmqHost = ips.rabbitMqGCP
        } else if (this.selectedPlatform === 'Azure') {
          this.rabbitmqHost = ips.rabbitMqAzure
        }
      }
      sapCustomers.get().then(doc => {
        const sap = doc.data().names
        if (sap.includes(this.selectedCustomer) && this.selectedPlatform === 'Azure') {
          this.steps = this.azureTmpSteps
        } else if (this.selectedPlatform === 'Azure') {
          if (this.discoveryMode) {this.steps = this.azureDiscoverySteps } else {this.steps = this.azureSteps }
        } else {
          this.steps = this.gcpSteps
        }
      })
      this.replaceDag()
    },
    discoveryMode: function() {
      if (this.discoveryMode) {this.steps = this.azureDiscoverySteps } else {this.steps = this.azureSteps }
    },
    // hiveIp: function () {
    //   this.setActive()
    // },
    redisIp: function () {
      this.setActive()
    },
    timestamp: function () {
      this.setActive()
    },
    calendarFileName: function () {
      this.setActive()
    },
    numJobs: function () {
      this.setActive()
    },
    metricName: function () {
      this.setActive()
    },
    validationDR: function () {
      this.setActive()
    },
    apniTS: function () {
      this.setActive()
    },
    baseCurrency: function () {
      this.setActive()
    },
    // branchName: function () {
    //   this.setActive()
    // },
    demandLabel: function () {
      this.setActive()
    },
    traditionalmodelLabel: function () {
      this.setActive()
    },
    configgenmodeLabel: function () {
      this.setActive()
    },
    etlDR: function () {
      this.setActive()
    },
    trainingDR: function () {
      this.setActive()
    },
    simulationDR: function () {
      this.setActive()
    },
    numSamples: function () {
      this.setActive()
    },
    rabbitmqHost: function () {
      this.setActive()
    },
    singlewarehouseAlgo: function () {
      this.setActive()
    },
    singlewarehouseTrainingProdList: function () {
      this.setActive()
    },
    trainingSteps: function () {
      this.setActive()
    },
    etlTables: function () {
      this.setActive()
    },
     testConfig: function () {
      this.setActive()
    },
     output_dir: function () {
      this.setActive()
    },
     sim_d_range_rolling: function () {
      this.setActive()
    },
     control_system: function () {
      this.setActive()
    },
     apni_schedule_file: function () {
      this.setActive()
    },
     apni_algo: function () {
      this.setActive()
    },
     freq: function () {
      this.setActive()
    },
     num_cpus: function () {
      this.setActive()
    },
     train_periods: function () {
      this.setActive()
    },
     val_periods: function () {
      this.setActive()
    },
     demand_table_rolling: function () {
      this.setActive()
    },
     mode_rolling: function () {
      this.setActive()
    },
     base_schedule_path: function () {
      this.setActive()
    },
     base_path: function () {
      this.setActive()
    },
     gridsearch_params: function () {
      this.setActive()
    },
     eval_config_path: function () {
      this.setActive()
    },
     output_configparam_dir: function () {
      this.setActive()
    },
     max_episode_length: function () {
      this.setActive()
    },
    granularity: function () {
      this.setActive()
    },
    dataPullMessage: function () {
      this.setActive()
    }
  },
  created () {
    let { rowId, platform } = this.$route.params
    const today = new Date()
    if (!platform) { platform = 'GCP' }
    if (rowId && platform) {
      this.fetchPipLineHistory(rowId, platform)
    }
    const customerNames = []
    const tempDictDev = {}
    const tempDictProd = {}
    const tempDictPersonal = {}
    ipCollection.get().then((snapshot) => {
      snapshot.docs.forEach(doc => {
        customerNames.push(doc.data().customer)
        tempDictDev[doc.data().customer] = {
          // hive: doc.data().hive_ip,
          redis: doc.data().dev_redis_ip,
          etlDR: doc.data().dev_date_range,
          trainingDR: doc.data().dev_training_date_range,
          simulationDR: doc.data().dev_simulation_date_range,
          rabbitMqGCP: doc.data().dev_rabbitMqGCP,
          rabbitMqAzure: doc.data().dev_rabbitMqAzure
        }
        tempDictProd[doc.data().customer] = {
          // hive: doc.data().prod_hive_ip,
          redis: doc.data().prod_redis_ip,
          etlDR: '2017-01-01:' + this.toJSONLocal(new Date(new Date(today).setDate(today.getDate() - 1))),
          // trainingDR: this.toJSONLocal(new Date(new Date(today).setMonth(today.getMonth()-6))) + ':' + this.toJSONLocal(new Date(new Date(today).setDate(today.getDate() - 1))),
          trainingDR: '',
          simulationDR: this.toJSONLocal(new Date(new Date(today).setDate(today.getDate() + 1)))+ ':' + this.toJSONLocal(new Date(new Date(today).setMonth(today.getMonth()+4))),
          rabbitMqGCP: doc.data().prod_rabbitMqGCP,
          rabbitMqAzure: doc.data().prod_rabbitMqAzure
        }
        tempDictPersonal[doc.data().customer] = {
          // hive: doc.data().hive_ip,
          redis: doc.data().personal_redis_ip,
          etlDR: doc.data().personal_date_range,
          trainingDR: doc.data().personal_training_date_range,
          simulationDR: doc.data().personal_simulation_date_range,
          rabbitMqGCP: doc.data().personal_rabbitMqGCP,
          rabbitMqAzure: doc.data().personal_rabbitMqAzure
        }
        this.customers = customerNames
        this.ipDictDev = tempDictDev
        this.ipDictProd = tempDictProd
        this.ipDictPersonal = tempDictPersonal
      })
    })
    stepsDoc.get().then((steps) => {
      this.gcpSteps = steps.data()
      stagingStepDoc.get().then((staging) => {
        this.gcpStagingSteps = staging.data()
        // if (this.selectedEnvironmentMode === 'staging') {
        //   this.steps = this.stagingSteps
        // } else { this.steps = this.pipelineSteps }
        // this.stepsKeys = Object.keys(this.steps).sort()
      })
    }).then(() => {
      azureStepsDoc.get().then((steps) => {
        this.azureTmpSteps = steps.data()
        // const targetSteps = steps.data()
        // if (Object.keys(targetSteps).includes('sap_bapi_xml_generator')) {
        //   delete targetSteps.sap_bapi_xml_generator
        //   if (Object.keys(targetSteps).includes('delete_cluster')) {
        //     targetSteps.delete_cluster.order = targetSteps.delete_cluster.order - 1
        //   }
        // }
        this.azureSteps = this.azureTmpSteps
        azureDiscoveryStepsDoc.get().then((steps) => {
        this.azureDiscoverySteps = steps.data()

          azureStagingStepDoc.get().then((staging) => {
            this.azureStagingSteps = staging.data()
            if (this.selectedEnvironmentMode === 'staging') {
              // this.steps = this.stagingSteps
              if (this.selectedPlatform === 'GCP') { this.steps = this.gcpStagingSteps } else if (this.selectedPlatform === 'Azure') { this.steps = this.azureStagingSteps }
            } else {
              if (this.selectedPlatform === 'GCP') { this.steps = this.gcpSteps } else if (this.selectedPlatform === 'Azure') { if (this.discoveryMode) {this.steps = this.azureDiscoverySteps } else {this.steps = this.azureSteps }}
            }
            if (Object.keys(this.steps).length > 0) { this.stepsKeys = Object.keys(this.steps).sort() }
          })
        })
      })
    }).then(() => {
      this.populateRepoBranches()
    })
      .catch((error) => {
        console.log(error)
      })
    //this.user = auth.currentUser.email.split('.')[0].toLowerCase()+auth.currentUser.email.split('.')[1].toLowerCase()
    this.user = auth.currentUser.email.split('@')[0].toLowerCase().split('.').join('')
    this.slack_username = auth.currentUser.email.split('@')[0].toLowerCase()
    if (this.user.indexOf('@') !== -1) this.user = auth.currentUser.email.split('@')[0].toLowerCase()
    // this.user = this.user.charAt(0).toUpperCase() + this.user.substring(1)
    // WOULD CONVERT 'test' to 'Test'
  }
}
</script>

<style lang="css">
table {
  width: 100% !important;
  border-radius: 5px;
  /* margin: auto; */
}
.adjust_div {
  padding: 1px 6px !important;
  border-radius: 5px !important;
}
#dag_name::placeholder {
  color: black !important;
}
#dag_name {
  color: black;
  border: 3px solid #1976d2 !important;
}
#incorta_settings label,
input {
  color: black !important;
}
#incorta_settings input::placeholder {
  color: rgb(160, 150, 150) !important;
}
/* #dag_name:focus{
  border: none !important;
  box-shadow: none !important;
  background-color: white;
  color: black;
} */
form span {
  font-size: 16px !important;
}
form span::after {
  border-color: rgb(9, 214, 9) !important;
}
g.node {
  font-size: 5px !important;
}
.checkboxes {
  position: relative;
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: left;
  width: 280px;
  display: inline-block;
  padding: 0px;
}
.checkContainer {
  margin: auto;
  width: 90%;
  text-align: center;
  position: relative;
  padding: 0px 20px 20px 20px;
  margin-bottom: 30px;
}
#check1 {
  display: inline-block;
  margin-bottom: 0px;
  margin: 0px 20px 0px 20px;
  vertical-align: top;
}
#check2 {
  margin-top: 0px;
  display: inline-block;
  margin: 0px 20px 0px 20px;
  vertical-align: top;
}
@media screen and (max-width: 992px) {
  #checkboxes {
    width: 100%;
  }
  .checkContainer {
    margin-left: 8% !important;
  }
}
@media screen and (min-width: 992px) {
  .checkContainer {
    margin-left: 18% !important;
  }
}
</style>

<style lang="css">

/* tag input style start  */
.input-wrapper {
  width: 100% !important ;
}
.tags-container {
  width: 100% !important;
}
.input-wrapper > input[type="text"] {
  border-bottom: 1px solid #ccc;
}
.tags-main {
  padding: 1rem 0 !important;
}
.vue-tags-input {
  background: transparent !important;
}
.ti-new-tag-input-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}
.ti-input {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
}
.ti-tag {
  background-color: #ccc !important;
  color: black !important;
}
/* tag input style end  */
</style>
