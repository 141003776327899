<template>
    <div class="logincontainer">
       <div class="screen">
		<div class="screen__content">
			<form class="login" method="post" id="login-form">
				
				<h3 class="loginHeading" >Seeloz Admin Dashboard</h3>
				<a class="button login__submit"  @click="loginWithGoogle()" id="google_login_btn">
					<span class="button__text">Log In Now</span>
					<i class="button__icon fas fa-chevron-right"></i>
        </a>				
			</form>
			<div class="social-login">
				<h4 class="loginHeading" style="color:white;font-size:x-large">Log in via</h4>
				
				<div class="social-icons">
					<a @click="loginWithGoogle()" class="social-login__icon fab fa-google" id="google_login_btn2"></a>
				</div>
			</div>
		</div>
		<div class="screen__background">
			<span class="screen__background__shape screen__background__shape4"></span>
			<span class="screen__background__shape screen__background__shape3"></span>		
			<span class="screen__background__shape screen__background__shape2"></span>
			<span class="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
    </div>
</template>

<script lang="js">
import { firebase, auth } from '../firebase'
import router from '../router'

export default {
  name: 'AdminLogin',
  data: function () {
    return {
      email: '',
      password: '',
      error: '',
      renderError: true
    }
  },
  methods: {
    login () {
      const userEmail = this.email
      const userPassword = this.password
      try {
        if (this.extractDomain(userEmail) === 'seeloz.com') {
          auth.signInWithEmailAndPassword(userEmail, userPassword).then(() => {
            router.push('/')
            this.renderError = false
          }).catch((error) => {
            this.error = error.message
            this.renderError = true
          })
        } else {
          this.error = 'Please use a seeloz.com email.'
          this.renderError = true
        }
      } catch (exception) {
        this.error = 'Please enter a valid email address and password.'
      }
    },
    extractDomain: function (email) {
      return email.split('@')[1].trim()
    },
    loginWithGoogle: function () {
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.setCustomParameters({
        hd: 'seeloz.com'
      })
      auth.signInWithPopup(provider).then((result) => {
        const user = result.user
        const domain = this.extractDomain(user.email)
        if (domain !== 'seeloz.com') {
          user.delete().then(() => {
            console.log('Invalid domain: ' + domain)
          }).catch((error) => {
            console.log(error)
          })
          this.renderError = false
        }
        router.push('/')
      }).catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        const email = error.email
        const credential = error.credential

        this.error = errorMessage
        this.renderError = true

        console.warn(errorCode + ': ' + errorMessage)
        console.log(email + ' via ' + credential)
      })
    }
  }
}
</script>


