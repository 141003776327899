import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyC-oypLwIWGUVlgo6BjZWHRJqN411je3ZQ',
  authDomain: 'development-233900.firebaseapp.com',
  databaseURL: 'https://development-233900.firebaseio.com',
  projectId: 'development-233900',
  storageBucket: 'development-233900.appspot.com',
  messagingSenderId: '137710848401',
  appId: '1:137710848401:web:2cb92249b18c6518ce3230'
}
// const name = 'develop'

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const auth = firebase.auth()

const getCurrentFirebaseUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

/* CAN BE INCLUDED FOR SESSIONS THAT END UPON CLOSING TAB
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
  .then(function () {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  })
  .catch(function (error) {
    // Handle Errors here.
    const errorCode = error.code
    const errorMessage = error.message
  })
*/

const ipCollection = db.collection('IP')
const stepsDoc = db.collection('admin_dashboard').doc('custom_steps')
const stagingStepDoc = db.collection('admin_dashboard').doc('custom_steps_staging')
const azureStepsDoc = db.collection('admin_dashboard').doc('custom_step_azure_test')
const azureStagingStepDoc = db.collection('admin_dashboard').doc('custom_steps_staging_azure')
const azureDiscoveryStepsDoc = db.collection('admin_dashboard').doc('custom_steps_discovery_azure')
const sapCustomers = db.collection('admin_dashboard').doc('sap_customers')

export {
  db,
  auth,
  ipCollection,
  stepsDoc,
  stagingStepDoc,
  azureStepsDoc,
  azureStagingStepDoc,
  azureDiscoveryStepsDoc,
  sapCustomers,
  firebase,
  getCurrentFirebaseUser
}
