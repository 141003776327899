<template>
    <div v-if="authenticated">
        <div>
            <nav>
                <div class="nav-wrapper test darken-2">
                    <div id="section1">
                      <a data-target="slide-out" class="sidenav-trigger"><i class="material-icons">menu</i></a>
                    </div>
                    <div id="section2">
                      <div class="row" style="display:inline-block" @click="sidenavShow=false">
                        <router-link to="/" class="brand-logo">Seeloz</router-link>
                      </div>
                      <ul id="nav-mobile" class="right hide-on-med-and-down">
                  <li title="notifacation" id="notification">
                    <img @click="routeTo" src="../assets/bell-01.png" alt="" />
                    <span class="count">{{count}}</span>
                  </li>
                <li title="User" id="user_loggedIn">{{ user }}</li>
                          <li><a @click="logOut()" class="center">Logout</a></li>
                      </ul>
                      <ul class="right logout-section-mobile">
                        <li><a @click="logOut()" class="center logout-btn"><i class="material-icons">logout</i></a></li>
                      </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <transition name="fade" v-if="authenticated">
      <div id="slide-out" class="sidenav sidenav-fixed grey lighten-3">
          <!-- <span class="in-menu-trigger" data-target="slide-out"><i class="material-icons sidenav-trigger">close</i></span> -->
          <div class="clearfix"></div>
          <div class="section"></div>
          <ul>
            <li><h5 class="center"><router-link to="/" class="brand-logo" style="color:#E06635;">Seeloz</router-link></h5></li>
            <h6 class="user">{{name}}</h6>
            <!-- <li title="notifacation" id="notification">
              <img src="../assets/bell-01.svg" alt="" />
              <span class="count">1</span>
            </li> -->
            <div class="sidenav-titles">MENU</div>
            <li class="links"><router-link to="/"><i class="material-icons">home</i>Home</router-link></li>
            <li class="links"><router-link to="/history"><i class="material-icons">history</i>Pipeline History</router-link></li>
            <li class="links"><router-link to="/create_pipeline"><i class="material-icons">build</i>Create Pipeline</router-link></li>
            <!-- <ul class="collapsible">
              <li>
                <div class="links collapsible-header" @click="togglePipeline()"><i class="material-icons">filter_drama</i>Pipelines<i class="material-icons sidenav-expand">{{ expandPipeline }}</i></div>
                <transition name="slide">
                  <ul class="container" style="width: 100%" v-if="pipelineShow">
                    <li class="pipeline-links"><router-link to="">A</router-link></li>
                    <li class="pipeline-links"><router-link to="">B</router-link></li>
                  </ul>
                </transition>
              </li>
            </ul> -->
            <div class="sidenav-titles">ACCOUNT</div>
            <li class="links"><router-link to=""><i class="material-icons">settings</i>Settings</router-link></li>
            <li class="links"><router-link to=""><i class="material-icons">help</i>Help</router-link></li>
          </ul>
      </div>
    </transition>
</template>

<script lang="js">
import { auth } from '../firebase'
import { mapState } from 'vuex'
import router from '../router'

export default {
  name: 'Header',
  data: function () {
    return {
      user: 'User',
      name: 'User',
      authenticated: true,
      pipelineShow: false,
      expandPipeline: 'expand_more',
      sidenavKey: 0
    }
  },
  computed: {
    ...mapState(['count'])
  },
  methods: {
    routeTo () {
      this.$router.push('/history')
    },
    logOut: function () {
      auth.signOut().then(() => {
        // Sign-out successful.
        router.push({ path: 'login' })
      }).catch(function (error) {
        // An error happened.
        console.log(error.message)
      })
    },
    togglePipeline () {
      this.pipelineShow = !this.pipelineShow
      this.expandPipeline = this.expandPipeline === 'expand_more' ? 'expand_less' : 'expand_more'
    }
  },
  created () {
    auth.onAuthStateChanged((user) => {
      if (user && window.location.pathname !== '/login') {
        const user_email = user.email
        const username = user_email.slice(0, user_email.indexOf('@'))
        this.user = username
        if (this.user === 'test') this.name = 'DEV ACCOUNT'
        else if (username.indexOf('.') !== -1) this.name = username.slice(0, 1).toUpperCase() + username.slice(1, username.indexOf('.')).toLowerCase()
        this.authenticated = true
        this.$store.dispatch('fetchPipLineHistory')
      } else {
        this.authenticated = false
      }
    })
  },
  watch: {
    $route () {
      this.sidenavShow = false
    }
  }
}
</script>

<style scoped>
#user_loggedIn {
  margin-right: 10px;
  margin-left: 10px;
}
.brand-logo {
  position: relative;
  align-self: center;
}
#nav-mobile {
  position: absolute;
  display: inline-block;
  right: 0px;
}
.sidenav-trigger {
  position: absolute;
  display: inline-block;
}
#section1 {
  text-align: left;
}
#section2 {
  text-align: center;
}
#select {
  padding-left: 12px;
  width: 99%;
}
.test{
  background:#E06635;
}
#notification  {
  position: relative;
}

#notification > .count {
   width: 17px;
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  background-color: #fff;
  display: inline-block;
  top: 20px;
  position: absolute;
  left: 15px;
  color: rgb(25, 118, 210);
  font-weight: 700;
}
#notification > img {
  border-style: none;
  width: 30px;
  height: auto;
  margin-top: 15px;
 margin-right: 18px;

}

.logout:hover {
  cursor: pointer;
}
ul {
   cursor: pointer;
}
a {
  cursor: pointer;
}
.links {
  text-align: left;
}
.links:hover {
  transform: scale(1.01);
  transition-duration: 0.2s;
}
.user {
  margin-left: 20px;
  margin-right: 20px;
  display: block;
  float: none;
  border-bottom: 1px solid gray;
  padding: 20px 0px 10px 0px;
}
.sidenav-titles {
  margin: 20px;
  text-align: left;
}
.in-menu-trigger {
  position: absolute;
  right: 35px;
  top: 15px;
  cursor: pointer;
  font-size: 14px;
  transition: linear 0.1s;
}
.in-menu-trigger:hover {
  color: gray;
}
.pipeline-links {
  text-align: left;
}
.sidenav-expand {
  position: absolute;
  right: 0px;
}
.sidenav {
  position: fixed !important;
  height: 100% !important;
}
</style>
