import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import naive from 'naive-ui'


import { SmartTagz } from 'smart-tagz'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(SmartTagz)
app.use(naive)


app.mount('#app')
