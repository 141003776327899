<template>
    <div>
        <!-- Side navbar starts here -->
        <div id="slide-out" class="sidenav">
        <span><i class="material-icons closing black-text right">close</i></span>
        <div class="clearfix"></div>
        <ul>
            <li><a onclick="logOut()" href="JavaScript:void(0)" class="center">Logout</a></li>
        </ul>
        </div>
        <br>
        <br>
        <div class="section"></div>
        <div id="selection_container" class="container center row">
            <div class="col s12 m6">
                <div class="container grey lighten-2 z-depth-4 box_option">
                    <div class="col s12 ">
                        <i class="material-icons icon">history</i>
                    </div>
                    <div class="col s12 text_box">
                        <h4>Pipeline History</h4>
                        <router-link to="/history">
                            <a class="btn test darken-2" >View</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col s12 m6">
                <div class="container grey lighten-2 z-depth-4 box_option">
                    <div class="col s12 ">
                        <i class="material-icons icon">build</i>
                    </div>
                    <div class="col s12 text_box">
                        <h4>Create Pipeline</h4>
                        <router-link to="/create_pipeline">
                            <a class="btn darken-2 test" >Build</a>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home'
})
</script>

<style scoped>
#selection_container {
    padding: 10px;
}
.icon {
    font-size: 100px;
}
.text_box {
    height: 100px;
}
.box_option {
    padding: 20px 5px;
    border-radius: 10px;
    font-family: Verdana, sans-serif
}
.container {
    height: 250px;
}
h4 {
    font-size: 27px;
    margin-bottom: 20px;
}
.test{
    background: black;
}
</style>
