import { createRouter, createWebHashHistory } from 'vue-router'
import Home from './components/Home.vue'
import CreatePipeline from './components/CreatePipeline.vue'
import AdminLogin from './components/AdminLogin.vue'
import PipelineHistory from './components/PipelineHistory.vue'
import Error404 from './components/Error404.vue'
import { getCurrentFirebaseUser } from './firebase'

const routerHistory = createWebHashHistory()
const router = createRouter({
  history: routerHistory,
  strict: true,
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/create_pipeline',
      name: 'CreatePipeline',
      component: CreatePipeline,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/history',
      component: PipelineHistory,
      name: 'PipelineHistory',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      component: AdminLogin,
      name: 'AdminLogin',
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/404',
      component: Error404,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !await getCurrentFirebaseUser()) {
    // TODO: router.push may be redundant
    router.push('/login')
    next('/login')
  } else {
    next()
  }
})

export default router
