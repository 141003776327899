<!-- @format -->

<template>
  <div class=" container row accordianContainer">
    <p @click="isCollasp = !isCollasp">
      <span>Show More Fields</span>
      <span class="accordian-icon"
        ><img
          src="../assets/add.png"
          alt=""
          :class="{ 'icon--rotate': !isCollasp }"
      /></span>
    </p>

    <div class="content" :class="{ collasp: isCollasp }">
      <slot name="inputFields"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordian',
  data () {
    return {
      isCollasp: false
    }
  }
}
</script>

<style scoped>
.accordianContainer > p {
  background: #ccc;
  padding: 15px 34px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: box-shadow 0.5s;
  cursor: pointer;
  position: relative;
}

.accordianContainer > p:hover {
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.3);
}

.accordian-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.accordian-icon > img {
  height: 30%;
  position: absolute;
  right: 20px;
  top: 19px;
  transition: transform 0.5s;
}

.icon--rotate {
  transform: rotate(45deg);
}

.accordianContainer > .icon-rotate:after {
  transform: rotate(90deg);
}
.content {
  max-height: 500px;
  transition: max-height 0.5s;
}
.collasp {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
</style>
