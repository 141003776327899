<template>
  <div class="row container">
    <!-- Main Heading -->
    <h1 class="center" black-text>Pipeline History</h1>

    <!-- this is our dropdown selector -->
    <div class="textInput">
      <div
        class="input-field"
        style="width: 130px; margin: 0 auto; text-align: center; margin-right: 1rem;
}"
      >
        <select
          id="platform"
          class="browser-default grey lighten-1"
          v-model="selectedPlatform"
        >
          <option disabled selected value>Select Platform</option>
          <option
            v-for="platform in platforms"
            :key="platform"
            :value="platform"
          >
            {{ platform }}
          </option>
        </select>
      </div>
      <br />
      <div
        class="input-field"
        style="width: 130px; margin: 0 auto; text-align: center"
      >
        <select
          id="platform"
          class="browser-default grey lighten-1"
          v-model="selectedCustomer"
        >
          <option disabled selected value>Select Customer</option>
          <option
            v-for="customer in customerNames"
            :key="customer"
            :value="customer"
          >
            {{ customer }}
          </option>
        </select>
      </div>
    </div>

    <!-- this is the loader isLoading -->
    <div v-if="isLoading" class="loaderPosition">
      <div class="customTableStyle loader"></div>
    </div>
    `

    <!-- this is the Datatable -->
    <div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col text-white">User</th>
            <th scope="col text-white">Pipeline_execution_date</th>
            <th scope="col text-white">Pipeline_status</th>
            <th scope="col text-white">Pipeline_duration</th>
            <th scope="col text-white">Pipeline_mode</th>
            <th scope="col text-white">Pipeline_id</th>
            <th scope="col text-white">Airflow_link</th>
            <th scope="col text-white"></th>
          </tr>
        </thead>
        <tbody v-for="row in historyData" :key="row.id">
          <tr>
            <td>{{ row.pipeline_user }}</td>
            <td>{{ row.pipeline_execution_date }}</td>
            <td>
              {{ row.pipeline_status }}

              <n-button @click="pausePipeline(row.pipeline_id)" 
                v-if="row.pipeline_status === 'running'" 
                type="warning" size="tiny">
                Pause
              </n-button>
            </td>
            <td>{{ row.pipeline_duration }}</td>
            <td>{{ row.pipeline_mode }}</td>
            <td>
              <span
                v-if="row.pipeline_status !== 'running' && row.pipeline_status !== 'paused'"
                @click="openModal(row.pipeline_id)"
                class="btn button-lower-case historyButton" 
              >
                {{ row.pipeline_id }}
              </span>
              <span v-else class="btn button-lower-case disabled">
                {{ row.pipeline_id }}
              </span>
            </td>
            <td>
              <a target="_blank" :href="`${row.airflow_link}`" >{{
                row.airflow_link
              }}</a>
            </td>
            <td>
              <!-- <router-link
                :to="{ name: 'CreatePipeline', params: { populateData: $data.rowId} }"
              > -->
              <button
                @click="getHistoryData(row.pipeline_id)"
                class="btn darken-2 historyButton" 
              >
                Build
              </button>
              <!-- </router-link> -->
            </td>

            <n-modal
              :show="showModal"
              preset="card"
              :onClose="() => (this.showModal = false)"
            >
              <n-data-table
                :bordered="true"
                :single-line="true"
                :columns="tasksColumns"
                :data="tasksData"
                :pagination="tasksPagination"
                @update:page="handlePageChange"
                @update:pageSize="handlePageSizeChange" 
              />
            </n-modal>
          </tr>
        </tbody>
      </table>
      <!-- Pagination buttons -->
      <div class="pagination">
        <button
          :disabled="isPreviousButtonDisabled()"
          @click="handlePagination('prev')"
          type="button"
          class="btn btn-outline-light"
        >
          <span> ← </span>
          Prev
        </button>
        <button
          :disabled="!isNextButtonDisabled()"
          @click="handlePagination('next')"
          type="button"
          class="btn btn-outline-light"
        >
          Next <span> → </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { auth, ipCollection } from '../firebase'
import { h, reactive } from 'vue'
import { NTag } from 'naive-ui'
export default {
  name: 'PipelineHistory',
  props: {
    comment: {
      type: String
    }
  },
  data () {
    return {
      platforms: ['Azure'], // dropdown selector values
      selectedPlatform: 'Azure',
      selectedCustomer: 'Ingress',
      historyData: [],
      customerNames: [],
      isLoading: false, // loader state
      totalResultCount: 0,
      gcpData: [],
      gcpDataCount: 0,
      azureData: [],
      azureDataCount: 0,
      // pagination variables
      currentPage: 1,
      resultsPerPage: 25,
      start: 0,
      end: 24,
      skipRecords: 0,
      rowId: '',
      runningCount: 0,
      showModal: false,
      tasksData: [],
      tasksColumns: [],
      tasksPagination: {
        page: 1,
        pageSize: 5,
        itemCount: 0,
        pageCount: 0,
        showSizePicker: true,
        pageSizes: [5, 10, 20],
        prefix(info){
            return `Total ${info.itemCount}`;
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    openModal(pipeline_id) {
      this.tasksData = this.createTasksData(pipeline_id)
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    createTasksData(pipeline_id) {
      const pipeline = this.historyData.find(p => p.pipeline_id === pipeline_id)
      return pipeline.pipeline_tasks.map(t => ({
        task_id: t.task_id,
        execution_date: t.task_execution_date,
        status: t.task_status,
        duration: `${t.task_duration} mins`,
        tag: t.anamoly ? "Anomaly" : "Standard",
        bounds: [`Lower: ${t.task_lower_bound}`, `Upper: ${t.task_upper_bound}`],
        log_url: t.task_log_url
      }))
    },
    createTaskColumns() {
      return reactive([
        {
          title: 'Task ID',
          key: 'task_id',
          width: 8000
        },
        {
          title: 'Execution Date',
          key: 'execution_date',
          width: 6000
        },
        {
          title: 'Status',
          key: 'status',
          render (row) {
            return h(
                NTag,
                {
                  style: {
                    marginRight: '6px'
                  },
                  type: row.status === "success" ? "success": "error"
                },
                {
                  default: () => row.status === "success" ? "Success": "Failed"
                }
              )
          }
        },
        {
          title: 'Duration',
          key: 'duration',
          width: 5000
        },
        {
          title: 'Tag',
          key: 'tag',
          render (row) {
              return h(
                NTag,
                {
                  style: {
                    marginRight: '6px'
                  },
                  type: row.tag === "Standard" ? "success": "error"
                },
                {
                  default: () => row.tag
                }
              )
          }
        },
        {
          title: 'Bounds',
          key: 'bounds',
          render (row) {
            const bounds = row.bounds.map((bound) => {
              return h(
                NTag,
                {
                  style: {
                    marginRight: '6px'
                  },
                  type: "info"
                },
                {
                  default: () => bound
                }
              )
            })
            return bounds
          }
        },
        {
          title: 'Log URL',
          key: 'log_url',
          width: 10000,
          render(row) {
            return h('a', {
              target: '_blank',
              href: row.log_url,
            }, [row.log_url])
          }
        },
      ])
    },
    handlePageChange(page) {
      this.tasksPagination.page = page;
    },
    handlePageSizeChange(pageSize) {
      this.tasksPagination.pageSize = pageSize;
    },
    pausePipeline(pipelineId) {
      auth.currentUser.getIdToken().then(token => {
        const payload = {
          pause: true,
          pipeline_id: pipelineId
        }
        const url = 'https://seelozairflow.azurewebsites.net/api/pipeline_history?auth=' + token + '&code=6PfLNoPJlpLwHT7EgNV3kUYLymqmH2kmvvWFFstB7RTrS1Pex8yz1g==&payload=' + JSON.stringify(payload)
        return fetch(url)
      })
      .then(response => response.json())
      .then(() => this.$router.go())
      .catch(err => console.log(err))
    },
    // make and api call for fetching data for Datatable
    getHistoryData (rowId) {
      // this.$emit('populate-history-data')
      this.$router.push({ name: 'CreatePipeline', params: { rowId, platform: this.selectedPlatform } })
    },
    fetchPipLineHistory (start, end) {
      this.isLoading = true
      let payload
      if (start || end) {
        payload = { start, end }
      } else {
        payload = 'somepayload'
      }
      auth.currentUser.getIdToken().then(token => {
        const headers = this.selectedPlatform === 'GCP' ? {
          headers: new Headers({
            Authorization: token
          })
        } : undefined
        fetch(
          'https://us-central1-development-233900.cloudfunctions.net/pipeline_history?payload=' + JSON.stringify(payload), headers)
          .then((response) => response.json())
          .then((data) => {
            this.gcpData = data.history
            this.gcpDataCount = this.gcpDataCount || data.count
            payload.customer = this.selectedCustomer
            payload.user = auth.currentUser.email.split('.')[0].toLowerCase()
            const url = 'https://seelozairflow.azurewebsites.net/api/pipeline_history?auth=' + token + '&code=6PfLNoPJlpLwHT7EgNV3kUYLymqmH2kmvvWFFstB7RTrS1Pex8yz1g==&payload=' + JSON.stringify(payload)
            return fetch(url)
          })
          .then(response => response.json())
          .then((data) => {
            data.history.forEach(h => {
              if (h.pipeline_duration && h.pipeline_duration.toString() !== '' && !h.pipeline_duration.toString().includes('mins')) {
                h.pipeline_duration = h.pipeline_duration.toFixed(1).toString() + ' mins'
              }
            })
            this.azureData = data.history
            this.azureDataCount = this.azureDataCount || data.count
            this.runningCount = data.running_count
          })
          .then(() => {
            if (this.selectedPlatform === 'GCP') {
              this.historyData = this.gcpData
              this.totalResultCount = this.gcpDataCount
            } else if (this.selectedPlatform === 'Azure') {
              this.historyData = this.azureData
              this.totalResultCount = this.azureDataCount
            }
            this.isLoading = false
          })
          .catch((err) => {
            console.log(err.message)
          })
      })
    },
    // disabled Prev Button
    isPreviousButtonDisabled () {
      return this.currentPage === 1
    },
    // disabled Next Button
    isNextButtonDisabled () {
      return this.totalResultCount > this.currentPage * 25 // 4 * 25
    },
    handlePagination (type) {
      if (type === 'next') this.currentPage++
      if (type === 'prev') this.currentPage--
      this.start = (this.currentPage - 1) * this.resultsPerPage
      this.end = this.currentPage * this.resultsPerPage - 1
      this.fetchPipLineHistory(this.start, this.end)
    }
  },
  created () {
    ipCollection.get().then((snapshot) => {
      snapshot.docs.forEach(doc => {
        this.customerNames.push(doc.data().customer)
      })
    })
    this.customerNames.push('Personal')
    // created methods run when component created
    this.fetchPipLineHistory(this.start, this.end)
    this.tasksColumns = this.createTaskColumns()
  },
  watch: {
    selectedPlatform: function (val) {
      if ((val.length > 0) && (val === 'GCP' || val === 'Azure')) {
        if (this.selectedPlatform === 'GCP') {
          this.historyData = this.gcpData
          this.totalResultCount = this.gcpDataCount
        } else if (this.selectedPlatform === 'Azure') {
          this.historyData = this.azureData
          this.totalResultCount = this.azureDataCount
        }
      }
    },
    selectedCustomer: function () {
      this.fetchPipLineHistory(this.start, this.end)
    }
  }
}
</script>
<style scoped>
.historyButton{
  background:#BDBDBC;
  color:black;
}
p {
  color: black;
  text-align: center;
}
.textInput {
  display: inline-flex;
  align-items: flex-start;
  text-align: center;
  margin-bottom: 2.8rem;
}
.textInput:first-child {
  margin: 0px auto;
  margin-right: auto;
  text-align: center;
  margin-right: 1rem;
}
.table tr,
.table td {
  color: black;
}
.table tr,
.table td,
.table th {
  border: 1.5px solid rgba(255, 255, 255, 0.12);
  text-align: center;
  padding: 7px 5px;
}
.table th {
  background: rgba(255, 255, 255, 0.12);
}
.pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
/* arror for pagination */
span {
  font-size: 20px;
}
/* loading  */
.loaderPosition {
  width: 45%;
  margin: 86px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
.button-lower-case {
  font-size: 12px;
  text-transform: lowercase;
}
@keyframes spin {
  0% {
    transform: rotate (0deg);
  }
  100% {
    transform: rotate (360deg);
  }
}
/* loading  */
</style>
